import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import styles from '../ProductReview/review.module.css'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection';
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
import ToasterUpdate from '../../Component/Toaster/ToasterUpdate'
import DropInput from '../../Component/DropDownComp/DropInput'
import NewPagination from '../../Component/NewPagination/NewPagination'
import NotFound from '../../Component/NotFound/NotFound'
import Moment from "react-moment"
import { FaRegCheckCircle } from 'react-icons/fa';
import { RiCloseCircleLine } from 'react-icons/ri';
import formatter from '../../Component/NumberFormatter/formatter';
import { FormatNumber } from '../../Component/FormatNumber/FormatNumber';
import WithDrawalModal from '../../Component/DeleteModal/WithdrawalModal';

const WithDrawalPayment = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [page, setPage] = useState(1)
  const [pageInfo, setPageInfo] = useState({})
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [search1, setSearch1] = useState('')
  const [loading, setLoading] = useState(false)
  const [ID, setID] = useState('')
  const [itemData, setItemData] = useState({})
  const [productSearch, setProductSearch] = useState('')
  const [modalVisible, setModalVisible] = useState(false);
  const[IDapprove, setIdapprove] = useState('')
    const [modalDisapprove, setModalDisapprove] = useState(false);
  const[IDreject, setIdReject] = useState('')

  const openApproveModal = (id) => {
    // console.log(id)
    setIdapprove(id)
    setModalVisible(true);
  };

  const closeDisapproveModal = () => {
    setModalDisapprove(false);
  };
  const openDisapprove= (id) => {
    // console.log(id)
    setIdReject(id)
    setModalDisapprove(true);
  };

  const closeApproveModal = () => {
    setModalVisible(false);
  };

  const navigate = useNavigate()
  let profileTokenJson = Cookies.get("v&b_token");
  let profileToken = JSON.parse(profileTokenJson);
  // console.log(profileToken.token)
  let token = profileToken.token;

  const getData = async () => {
    // console.log(register)

    let res = await axios.get(`${BASE_URL}/withdraw-request?limit=${limit}&page=${page}&status=${productSearch}&search=${search}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token} `,
      },
    })
    console.log(res.data.data)
    setData(res.data.data?.docs)
    setPageInfo({ ...res.data.data, docs: null })
  }

  useEffect(() => {
    getData()
  }, [limit, page, productSearch, search])


  useEffect(() => {
    setPage(1)
}, [limit]
)

  const closeModal = (id) => {
    setIsOpen(false)
  }
  const openModal = (id, item) => {
    setIsOpen(true)
    setID(id)
    setItemData(item)
  }

  const paginationProps = {
    setPage, pageInfo, setLimit
  }
  // const handleAccept = async (id) => {
  //   // e.preventDefault()
  //   console.log(id)
  //   let register = `${BASE_URL}/withdraw-request/${id}/status`
  //   const myToast = toast.loading('Please Wait...')
  //   // console.log(register)
  //   try {

  //     let res = await axios.put(register, { status: "paid" }, {
  //       // withCredentials : true,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token} `,
  //       },
  //     })

  //     if (!res.data.success) {
  //       ToasterUpdate(myToast, res.data.message, "error")
  //     }
  //     ToasterUpdate(myToast, res.data.message, "success")
  //     getData()
  //   } catch (error) {
  //     // console.log(error)
  //     ToasterUpdate(myToast, error.message, "error")
  //   }

  // };
  // const handleReject = async (id) => {
  //   // e.preventDefault()
  //   console.log(id)
  //   let register = `${BASE_URL}/withdraw-request/${id}/status`
  //   const myToast = toast.loading('Please Wait...')
  //   // console.log(register)
  //   try {

  //     let res = await axios.put(register, { status: "rejected" }, {
  //       // withCredentials : true,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token} `,
  //       },
  //     })

  //     if (!res.data.success) {
  //       ToasterUpdate(myToast, res.data.message, "error")
  //     }
  //     ToasterUpdate(myToast, res.data.message, "success")
  //     getData()
  //   } catch (error) {
  //     // console.log(error)
  //     ToasterUpdate(myToast, error.message, "error")
  //   }

  // };
  console.log(productSearch)
  return (
    <React.Fragment>
      <Helmet>
        <title>Withdrawal Requests</title>
      </Helmet>
      <TopNavbar />
      <NavbarSection />

      {/* ----------Hero-Section----------- */}

      <div className={styles.hero_Section}>
        {/* <ContainerSection /> */}
        <div className={styles.product_review_section}>
            <h6 className='mb-0'>Withdrawal Requests</h6>
            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} style={{
              height: "40px",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #d9d9d9", outline: "none"
            }} placeholder="Search..." required />

          </div>
        {/* <div className={styles.product_review_section}> */}
          {/* <h6>Withdrawal Request</h6> */}
          {/* <div className={styles.dropdown_section}>
            <DropInput search={search} setSearch={setSearch} setProductSearch={setProductSearch} />
          </div> */}
          <div className="mt-4">
            <div className={styles.filterData}>
              <p>Filters : </p>
              <p>Status </p>
              <div className="form-check">

                <input className={`${styles.form_check_input}`} type="radio"
                  name="flexRadioDefault" id="flexRadioDefault2"
                  value=""
                  onChange={(e) => setProductSearch(e.target.value)}
                  checked={productSearch === ''} />
                <label className="form-check-label mx-1" for="flexRadioDefault2">
                  All
                </label>
              </div>
              <div className="form-check">

                <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                  value="paid"
                  onChange={(e) => setProductSearch(e.target.value)} checked={productSearch === 'paid'} />
                <label className="form-check-label mx-1" for="flexRadioDefault2">
                  Paid
                </label>
              </div>
              <div className="form-check">

                <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                  value="pending"
                  onChange={(e) => setProductSearch(e.target.value)} checked={productSearch === 'pending'} />
                <label className="form-check-label mx-1" for="flexRadioDefault2">
                  Pending
                </label>
              </div>
              <div className="form-check">

                <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                  value="rejected"
                  onChange={(e) => setProductSearch(e.target.value)} checked={productSearch === 'rejected'} />
                <label className="form-check-label mx-1" for="flexRadioDefault2">
                  Rejected
                </label>
              </div>


            </div>
            {/* <div className={styles.btn_section}>
                        <button>Add Product</button>
                    </div> */}
          </div>

        {/* </div> */}


        {/* -------------table_section-------------- */}
        {data?.length > 0 ?
        <>
          <div className={styles.main_table}>
            <table className="table">
              <thead>
                <tr>
                  {/* <th scope="col">Sno</th> */}

                  <th className='px-5'>Seller Name</th>
                  <th >V&B ID</th>
                  <th >Withdrawal Amount</th>
                  <th >Available Balance</th>
                  <th >Date</th>
                  {/* <th scope="col">Status</th> */}
                  <th >Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr>
                    {/* <td>{index + 1}</td> */}
                    <td className='px-5 text-capitalize' >
                      {item?.user?.name}
                    </td>
                    <td className='px-5'>
                      {item?.vbID}
                    </td>
                    <td>{FormatNumber(item?.amount || 0)}</td>
                    <td>{FormatNumber(item?.user?.availableBalance || 0)}</td>
                    <td><Moment format='YYYY/MM/DD'>{item.createdAt}</Moment></td>
                    {/* <td>{item?.status}</td> */}

                    <td>

                      {item.status == "pending" ? <>
                        <div className='d-flex gap-3 align-items-center' >
                          <FaRegCheckCircle style={{ cursor: "pointer" }} className="text-success fs-5" 
                          // onClick={() => handleAccept(item._id)}
                          onClick={()=> openApproveModal(item._id)}
                          />
                          <RiCloseCircleLine style={{ cursor: "pointer" }}
                            className="text-danger fs-4 border-2"
                            //  onClick={() => handleReject(item._id)} 
                            onClick={()=> openDisapprove(item._id)}

                             />
                        </div>
                        </> :(<p className='text-capitalize'> {item.status == "paid" ? (<span className='bg-success px-2 py-1 text-white rounded-3' style={{fontSize:"12px"}}>{item.status}</span>) : <span className='bg-danger px-2 py-1 text-white rounded-3' style={{fontSize:"12px"}}>{item.status}</span>} </p>)
      }

                    </td>

                  </tr>
                ))}

              </tbody>
            </table>
            </div>
            <NewPagination {...paginationProps} />
          </>

          : <NotFound cls={styles.not_found} />}
      </div>
      {modalDisapprove && <WithDrawalModal type="put" status="rejected" action={"Reject"} id={IDreject} title="withdraw" show={modalDisapprove} handleClose={closeDisapproveModal} getData={getData} />}
      {modalVisible && <WithDrawalModal type="put" title="withdraw" status="paid" action={"Approve"} id={IDapprove} show={modalVisible} handleClose={closeApproveModal} getData={getData} />}


    </React.Fragment>
  )
}

export default WithDrawalPayment