import React, { useEffect, useState } from "react";
import styles from "./modal.module.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import ToasterUpdate from "../../Component/Toaster/ToasterUpdate";
import { BASE_URL } from "../../Apis/BaseUrl";

const CommissionModal = ({ show, closeModal, getData, id, defaultCommission = 0 }) => {
  const [commission, setCommission] = useState(0);
  const [loading, setLoading] = useState(false);

  let profileTokenJson = Cookies.get("v&b_token");
  let profileToken = JSON.parse(profileTokenJson);
  // console.log(profileToken.token)
  let token = profileToken.token;

  const handleSubmit = async () => {
    let bdy = {
      commission: commission,
    };
    let register = `${BASE_URL}/vendor/${id}/commission`;
    const myToast = toast.loading("Please Wait...");
    setLoading(true);
    try {
      let res = await axios.put(register, bdy, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token} `,
        },
      });
      console.log(res);
      if (!res.data.success) {
        ToasterUpdate(myToast, res.data.message, "error");
        return;
      }
      ToasterUpdate(myToast, res.data.message, "success");
      getData();
      // navigate('/product/categories')
    } catch (error) {
      // console.log(error)
      ToasterUpdate(myToast, error.message, "error");
    } finally {
      closeModal();
    }
  };

  useEffect(() => {
    if (show && defaultCommission) {
      setCommission(defaultCommission);
    }
  }, [show, defaultCommission]);

  return (
    <React.Fragment>
      <Modal show={show} onHide={closeModal}>
        <Modal.Title>
          {" "}
          <div className={styles.header}>
            <h6>Add Commission</h6>
          </div>
        </Modal.Title>

        <Modal.Body className={styles.bdy}>
          <div>
            <label htmlFor="Commision">Commission(in %) :</label>
            <input type="number" value={commission} onChange={(e) => setCommission(e.target.value)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.model_button}>
            <button className="bg-white text-black border" variant="primary" onClick={closeModal}>
              Cancel{" "}
            </button>
            <button variant="primary" onClick={handleSubmit}>
              {" "}
              Add{" "}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default CommissionModal;
