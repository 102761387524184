import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import styles from './seller.module.css'
import { BsThreeDots } from "react-icons/bs";
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../Apis/BaseUrl';
import axios from 'axios';
import NewPagination from '../../Component/NewPagination/NewPagination';
import { toast } from 'react-toastify';
import ToasterUpdate from '../../Component/Toaster/ToasterUpdate';
import NotFound from '../../Component/NotFound/NotFound';
import CommissionModal from './CommissionModal';
import DeleteModal from '../../Component/DeleteModal/DeleteModal';


const Sellers = () => {
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [pageInfo, setPageInfo] = useState({})
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState({});
  const [toggleData, setToggleData] = useState('')
  const [show, setShow] = useState(false)
  const [ID, setID] = useState('')
  const [status, setStatus] = useState('')
  const [modalVisible, setModalVisible] = useState(false);
  const [IDdelete, setIDdelete] = useState('')

  const openModal = (id) => {
    // console.log(id)
    setIDdelete(id)
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };


  const handleShow = (item) => {
    setID(item)
    setShow(true)
  }
  const handleClose = () => setShow(false)

  let profileTokenJson = Cookies.get("v&b_token");
  let profileToken = JSON.parse(profileTokenJson);
  // console.log(profileToken.token)
  let token = profileToken.token;

  const getData = async () => {

    let register = `${BASE_URL}/vendor?limit=${limit}&page=${page}&search=${search}&status=${status}`

    // console.log(register)
    let res = await axios.get(register, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
    console.log(res.data.data.docs)
    setData(res.data.data.docs)
    setPageInfo({ ...res.data.data, docs: null })
  }

  useEffect(() => {
    getData()
  }, [limit, page, search, status])


  
useEffect(() => {
  setPage(1)
}, [limit]
)

  const paginationProps = {
    setPage,
    pageInfo, setLimit
  }
  // const navigate = useNavigate()

  const toggleMenu = (id) => {
    console.log(id)
    let div_id = id?.target?.id
    console.log(div_id)
    console.log(toggleData)
    if (div_id) {
      if (div_id == toggleData) {
        setToggleData('')

      } else {
        setToggleData(div_id)
      }
    } else {
      setToggleData('')
    }

    // if (div_id == "toggle_id") {
    //   setToggleData('')
    // }else if(div_id) {
    //   console.log("inside div")
    //   if (div_id == toggleData) {
    //     setToggleData('')
    //   }else{
    //     setToggleData(div_id)
    //   }
    // }else{
    //   console.log("bahar wala func")
    //   setToggleData('')
    // }
  };
  // console.log(isMenuOpen)
  // const handleDelete = async (id) => {
  //   // console.log(id)
  //   const myToast = toast.loading('Please Wait...')
  //   setLoading(true)
  //   try {
  //     let register = `${BASE_URL}/vendor/${id}/remove`

  //     const response = await axios.delete(register,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`
  //         }
  //       });
  //     console.log(response)
  //     toast.update(myToast, {
  //       render: response.data.message,
  //       type: 'success',
  //       isLoading: false,
  //       autoClose: 1500
  //     });

  //     getData()
  //   } catch (error) {
  //     toast.update(myToast, {
  //       render: error.message,
  //       type: 'error',
  //       isLoading: false,
  //       autoClose: 1500
  //     })
  //     console.error('Error deleting resource:', error);
  //   }
  //   finally {
  //     setLoading(false)
  //   }
  // }

  const handleAccept = async (id, item) => {
    // e.preventDefault()
    console.log(item)
    let register = `${BASE_URL}/user/${id}`
    const myToast = toast.loading('Please Wait...')
    // console.log(register)
    try {

      let res = await axios.patch(register, { active: !item.active }, {
        // withCredentials : true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token} `,
        },
      })

      if (!res.data.success) {
        ToasterUpdate(myToast, res.data.message, "error")
      }
      ToasterUpdate(myToast, res.data.message, "success")
      getData()
    } catch (error) {
      ToasterUpdate(myToast, error.message, "error")
    }
  };

  const vendorLoginHandler = async (id) => {
    const myToast = toast.loading('Please Wait...');
    try {
      const register = `${BASE_URL}/user/${id}/auth`;
      const response = await fetch(register, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to authenticate user');
      }

      const data = await response.json();

      toast.update(myToast, {
        render: data.message,
        type: 'success',
        isLoading: false,
        autoClose: 1500
      });

      const newPageUrl = `https://vb-vendor.netlify.app/admin_login/${data.data.resetToken}`;
      window.open(newPageUrl, '_blank');
    } catch (error) {
      toast.update(myToast, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 1500
      });
    }
  };


  return (
    <React.Fragment>
      <Helmet>
        <title>Sellers</title>
      </Helmet>
      <TopNavbar />
      <NavbarSection />

      {/* -----------------hero-section---------------- */}

      <div className={styles.hero_Section} onClick={(e) => toggleMenu(e)} >
        <ContainerSection />

        <div className={styles.filter_section}>
          <div className={styles.filter}>
            <p>Filters : </p>
            <p>Status </p>
            <div className="form-check">

              <input className={`${styles.form_check_input}`} type="radio"
                name="flexRadioDefault" id="flexRadioDefault2"
                value=""
                onChange={(e) => setStatus(e.target.value)}
                checked={status === ''} />
              <label className="form-check-label" for="flexRadioDefault2">
                All
              </label>
            </div>
            <div className="form-check">

              <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                value="active"
                onChange={(e) => setStatus(e.target.value)} checked={status === 'active'} />
              <label className="form-check-label" for="flexRadioDefault2">
                Active
              </label>
            </div>
            <div className="form-check">

              <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                value="inactive"
                onChange={(e) => setStatus(e.target.value)} checked={status === 'inactive'} />
              <label className="form-check-label" for="flexRadioDefault2">
                Inactive
              </label>
            </div>
          </div>
        </div>
        <div className={styles.mainSection}>


          <div className={styles.product_review_section}>
            <h6 className='mb-0'>Sellers</h6>
            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} style={{
              height: "40px",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #d9d9d9", outline: "none"
            }} placeholder="Search..." required />

          </div>


          {/* -------------table_section-------------- */}
          {data.length > 0 ?
            <>
              <div className={styles.main_table}>
                <table className="table">
                  <thead>
                    <tr>

                      <th className='px-5'>V&B ID</th>
                      <th>Store Name</th>
                      {/* <th scope="col">Seller Name </th> */}
                      <th >Primary Contact </th>
                      <th >Commission (%) </th>
                      {/* <th scope="col">Add Commission </th> */}
                      <th>Email ID </th>
                      <th >Status </th>
                      <th>No of products </th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => (
                      <tr>

                        <td className='px-5'>{item?.vbID ? item?.vbID : "_ _ _"}</td>
                        <td className='text-capitalize' >{item?.shopName ? item?.shopName : "_ _ _"}</td>
                        {/* <td>{item?.name}</td> */}
                        <td>{item?.primaryContact ? item.primaryContact : "_ _ _"}</td>
                        <td><div className='d-flex align-items-center gap-4'>
                          {item?.commission ? item.commission : "0"} <button style={{ background: "none", border: "none", color: "#AE0000", fontSize: "25px", borderRadius: "50%" }} onClick={() => handleShow(item)}>+</button></div></td>
                        {/* <td></td> */}
                        <td>{item.email}</td>
                        <td style={{paddingInline:"20px"}}>
                          <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={() => handleAccept(item._id, item)} checked={item.active} />
                          </div>
                        </td>
                        <td>{item?.totalProducts || 0}</td>
                        {/* <td></td> */}
                        <td><BsThreeDots size={22} id={item._id} onClick={() => toggleMenu(item._id)} />
                          <div className='d-flex justify-content-end position-relative'>
                            <div className={styles.list_section}>
                              {item._id == toggleData && (
                                <div className={styles.menu}>
                                  <ul>
                                    <li onClick={() => vendorLoginHandler(item._id)}>Log in as Seller</li>
                                    {/* <li onClick={() => navigate(`/seller/editseller`)}>Edit Seller</li> */}
                                    <li
                                    onClick={() => openModal(item._id)}
                                    //  onClick={() => handleDelete(item._id)}
                                     >Delete Seller</li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <NewPagination {...paginationProps} />

            </>
            : <NotFound />}


        </div>

      </div>
      {show && <CommissionModal id={ID?._id} show={show} defaultCommission={ID?.commission} closeModal={handleClose} getData={getData} />}
      {modalVisible && <DeleteModal title="seller" id={IDdelete} show={modalVisible} handleClose={closeModal} getData={getData} apiTitle="" />}
    </React.Fragment>
  )
}

export default Sellers