import React, { useEffect, useState } from 'react'
import styles from './home.module.css'
import Charts from 'react-apexcharts';
import { Col, Row } from 'react-bootstrap';
import { BsThreeDots } from "react-icons/bs";
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../Apis/BaseUrl';
import axios from 'axios';
import formatter from '../../Component/NumberFormatter/formatter';
import { useNavigate } from 'react-router-dom'
import { TiStarFullOutline } from "react-icons/ti";
import FallProduct from '../../Component/FallbackImgae/FallProduct';
import { IoNotifications } from 'react-icons/io5';
import { FormatNumber } from '../../Component/FormatNumber/FormatNumber';
import { FormatWithoutDoller } from '../../Component/FormatNumber/FormatWithoutDoller';

const td1 = [
    {
        h5: 'Total Sales',
        p: '0'
    },
    {
        h5: 'Total Orders',
        p: '0'
    },
    {
        h5: 'Monthly Orders',
        p: '0',
    },
  
    {
        h5: 'Today Orders',
        p: '0'
    },
    {
        h5: 'Earned Commission',
        p: '0'
    }
]

const Dashboard = () => {
    const [data, setData] = useState({})
    const [orderData, setOrderData] = useState([])
    const [bottomData, setBottomData] = useState({})
    const [reviewsData, setReviewsData] = useState([])
    const [graphData, setGraphData] = useState({})
    const[notification, setNotification] = useState([])

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    let token = profileToken.token;

    const navigate = useNavigate()
    const getNotification = async () => {
        const register = `${BASE_URL}/notification?limit=10?page=1`; //get id by props
    
        let res = await axios
          .get(register, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token} `,
            },
          })
        // console.log(res.data, "ttt");
        setNotification(res.data.data.docs);
      }

    const seenNotification = async (id) => {
        const register = `${BASE_URL}/notification/${id}/seen`;
    
        let res = await axios
          .put(register, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token} `,
            },
          })
        if(res.status === 200){
            getNotification();
        }
      }
      useEffect(()=>{
        getNotification()
      },[])
    const getTileData = async () => {

        let register = `${BASE_URL}/dashboard/tiles`
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res.data.data)
        td1[0].p = FormatNumber(res.data.data?.totalSales || 0)
        td1[1].p = FormatWithoutDoller(res.data.data?.totalOrders || 0)
        td1[2].p = FormatWithoutDoller(res.data.data?.monthlyOrders  || 0)
        td1[3].p = FormatWithoutDoller(res.data.data?.todayOrders)
        td1[4].p = FormatNumber(res.data.data?.commissionEarned || 0)
        // td1[4].p= formatter.format(res.data.data?.wallet ||0)
        setData(res.data.data)
        console.log(res.data.data)
    }

    useEffect(() => {
        getTileData()
    }, [])
    const getBottomTiles = async () => {

        let register = `${BASE_URL}/dashboard/bottom-tiles`
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        // console.log(res.data.data)

        setBottomData(res.data.data)
    }

    useEffect(() => {
        getBottomTiles()
    }, [])
    const getGraphData = async () => {

        let register = `${BASE_URL}/dashboard/graph`
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        // console.log(res.data.data)

        setGraphData(res.data.data)
    }

    useEffect(() => {
        getGraphData()
    }, [])
    const getReviewsData = async () => {

        let register = `${BASE_URL}/review?limit=5&page=1`
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        // console.log(res.data.data?.docs)

        setReviewsData(res.data.data?.docs)
    }

    useEffect(() => {
        getReviewsData()
    }, [])
    const getOrderData = async () => {

        let register = `${BASE_URL}/order?limit=6&page=1`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        // console.log(res.data.data.docs)
        setOrderData(res.data.data.docs)
    }

    useEffect(() => {
        getOrderData()
    }, [])
    // console.log(graphData.labels)
    return (
      <React.Fragment>
        <Helmet>
          <title>Home</title>
        </Helmet>
        <TopNavbar />
        <NavbarSection />

        {/* ----------Hero-Section----------- */}

        <div className={styles.hero_Section}>
          <ContainerSection data={td1} cls={styles.send_grid} />
          {/* {data?.map((item)=> console.log(item))} */}

          {/* ----------Second-box------------- */}

          <div className={styles.grid_container_second}>
            <div className={styles.second_box}>
              <div className={styles.header}>
                <h6 className="fw-bold">Action Needed</h6>
                {/* <BsThreeDots className={styles.icon} /> */}
              </div>
              {notification?.map((item) => (
                <div
                  className={styles.section}
                  style={{ cursor: "pointer" }}
                  onClick={() => seenNotification(item._id)}
                >
                  <div>
                    <IoNotifications />
                  </div>

                  <p>{item.subject}</p>
                </div>
              ))}
            </div>
            <div className={styles.second_box}>
              {/* <div className={styles.header}>
                            <h6 className='fw-bold'>Recent Orders</h6>
                        </div> */}
              <div className={styles.header}>
                <h6 className="fw-bold">Recent Orders</h6>
                <a target="_blank" href="/sales/allorder">
                  View More
                </a>
              </div>
              <div className={styles.news_section}>
                <table className="table" style={{ borderRadius: "5px" }}>
                  <thead>
                    <tr>
                      <th className="text-center fw-medium px-1">Order Id </th>
                      <th className="text-center fw-medium">Order Status</th>
                      <th className="text-center fw-medium">Payment Method</th>
                      <th className="text-center fw-medium"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData?.map((item, index) => (
                      <tr>
                        {" "}
                        <td style={{ border: "none" }} className="text-center">
                          #{item?.orderId}
                        </td>
                        <td style={{ border: "none" }} className="text-center text-capitalize px-1">
                          {item?.orderStatus}
                        </td>
                        <td className="text-center text-capitalize" style={{ border: "none" }}>
                          {item?.paymentMethod}
                        </td>
                        <td className="text-center text-capitalize" style={{ border: "none", cursor: "pointer" }}>
                          <img
                            onClick={() => navigate(`/sales/allorder/orderdetails/${item._id}`)}
                            src="/image/eye.png"
                            alt="edit"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className={styles.second_box}>
              <div className={styles.header}>
                <h6 className="fw-bold">Recent Products Reviews</h6>
                <a target="_blank" href="/product/productreview">
                  View More
                </a>
              </div>
              {reviewsData?.map((item) => (
                <div className={`${styles.products_section} align-items-center`}>
                  <div className="d-flex gap-2 align-items-center">
                    <FallProduct cls={styles.img_fb} imgData={item?.productId?.url} />
                    <h6 className="mb-0 mx-2 text-capitalize">
                      {" "}
                      {item?.productId?.name.length > 25
                        ? item?.productId?.name.slice(0, 25) + "..."
                        : item?.productId?.name}
                    </h6>
                  </div>
                  <p className="mb-0">
                    {" "}
                    {item?.rating} <TiStarFullOutline style={{ fill: "#ffc107", fontSize: "20px" }} />
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* -------------last_section------------- */}

          <Row className={`${styles.main_container_box} mt-4`}>
            <div className={styles.container_box}>
              <Col md={7} sm={12}>
                <div className={styles.graph}>
                  <div className="graph-section">
                    <Charts
                      type="bar"
                      width={"100%"}
                      height={400}
                      series={[
                        {
                          name: "Gross Earning",
                          data: graphData?.grossEarning?.map((value) => parseFloat(value.toFixed(0))) || [],
                        },
                        {
                          name: "Net Earning",
                          data: graphData?.netEarning?.map((value) => parseFloat(value.toFixed(0))) || [],
                        },
                      ]}
                      options={{
                        title: {
                          text: `Revenue In Last ${graphData?.labels ? graphData.labels.length : "0"} Month`, // Dynamic text

                          style: { fontSize: "15.14px", fontWeight: "600", paddingTop: "20px" },
                        },
                        colors: ["#AE0000", "#000000"],

                        xaxis: {
                          categories: graphData?.labels || [],
                        },
                        yaxis: {
                          labels: {
                            formatter: function (val) {
                              return FormatNumber(val); // Add dollar symbol to y-axis labels
                            },
                          },
                        },
                        stroke: {
                          show: true,
                          width: 4,
                          colors: ["transparent"],
                        },
                        dataLabels: {
                          enabled: false, // Disable data labels
                        },
                        tooltip: {
                          y: {
                            formatter: function (val) {
                              return FormatNumber(val); // Add dollar sign to the value
                            },
                          },
                        },
                      }}
                    ></Charts>
                  </div>
                </div>
              </Col>
              <Col md={5} sm={12}>
                <Row>
                  <Col md={6} sm={6}>
                    <div className={styles.total_section}>
                      <p>Today Sales</p>

                      <h6>{FormatNumber(bottomData?.todaySales || 0)}</h6>
                    </div>
                  </Col>
                  <Col md={6} sm={6}>
                    <div className={styles.small_box}>
                      <p>Weekly Sales</p>
                      <h6>{FormatNumber(bottomData?.weeklySales || 0)}</h6>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={6}>
                    <div className={`${styles.sm_box} ${styles.small_box}`}>
                      <p>Monthly Sales </p>
                      <h6>{FormatNumber(bottomData?.monthlySales || 0)}</h6>
                    </div>
                  </Col>
                  <Col md={6} sm={6}>
                    <div className={`${styles.sm_box} ${styles.small_box}`}>
                      <p> Yearly Sales</p>
                      <h6>{FormatNumber(bottomData?.yearlySales || 0)}</h6>
                      {/* <h6>{formatter.format(bottomData?.yearlySales || 0)}</h6> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        </div>
      </React.Fragment>
    );
}

export default Dashboard