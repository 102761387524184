import React, { useEffect, useRef } from 'react'
import classes from './AsyncSelect.module.css'
import { useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { IoChevronDown } from "react-icons/io5";
import { MdErrorOutline } from "react-icons/md";

const AsyncSelectData = ({placeholder,currentValue,valueHandler,data,dataHandler, cls}) => {
// console.log(placeholder,currentValue,valueHandler,data,dataHandler)
  
    const [visible, setVisible] = useState(false)
    const containerRef = useRef();
    const [actualValue,setActualValue] = useState('')

    const handleSelect = data => {
        setActualValue(data.label)
        valueHandler(data)
        setTimeout(()=>{
            setQuery('')
            setVisible(false)
        },100)
    }
    const handleFocus = () => {
        setVisible(true)
    }

    // Parent
    const [query, setQuery] = useState('')

    useEffect(() => {
        // getData()
        dataHandler(query)
    }, [query])

    // onBlur={()=>setVisible(!visible)}
     const handleClickOutside = event => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setQuery('')
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    const NoDataFound = () => {
        return (
            <div className={classes.no_data_found}>
                <MdErrorOutline className={classes.error_icon} />
                <h6>No Data Found</h6>
            </div>
        )
    }

    useEffect(()=>{
        setActualValue(currentValue?.label)
    },[])


    return (
        <div ref={containerRef} className={classes.container}>
            <input placeholder={actualValue ? actualValue : placeholder}  onFocus={handleFocus} className={`${classes.input} ${cls}`}  type="search" value={query} onChange={e => setQuery(e.target.value)} />
            <IoChevronDown className={classes.drop_down} />
            {
                visible &&

                <div  className={classes.options}>
                    {data.length > 0 ? data.map(element => (
                        <button className={classes.btn} type='button' key={element.value} onClick={() => handleSelect(element)}>{element.label}</button>
                    )) : <NoDataFound />}
                </div>  
            }  
        </div>
    )
}

export default AsyncSelectData
